import { render, staticRenderFns } from "./StepSixCommune.vue?vue&type=template&id=b6b7d6ca&scoped=true&"
import script from "./StepSixCommune.vue?vue&type=script&lang=ts&"
export * from "./StepSixCommune.vue?vue&type=script&lang=ts&"
import style0 from "./StepSixCommune.vue?vue&type=style&index=0&id=b6b7d6ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6b7d6ca",
  null
  
)

export default component.exports