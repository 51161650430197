
































/* eslint-disable */
import {useState} from '@u3u/vue-hooks'
import {defineComponent, ref} from '@vue/composition-api'
import UploadRecord from '@/components/CommuneUpload.vue'
import UploadRecordHeader from '@/components/CommuneUploadHeader.vue'

export default defineComponent({
  name: 'step-six-commune',
  components: {UploadRecord, UploadRecordHeader},
  props: {
    parentData: {
      type: Object,
      required: true,
    },
  },

  setup(_props, ctx) {
    const {user, chrome} = useState(['user', 'chrome'])
    const {i18n, i18nSuivi} = useState('my', ['i18n', 'i18nSuivi'])
    const SIX = ref('6')
    const SIXTYONE = ref('61')
    const SIXTYTWO = ref('62')

    //ScrollTop
    ctx.root.$nextTick(() => {
      const el = document.querySelector('.step-six')
      if (el) {
        el.scrollTop = el.scrollHeight
      }
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    })

    return {
      user,
      chromeI18n: chrome.value.data.i18n,
      i18n,
      i18nSuivi,
      SIX,
      SIXTYONE,
      SIXTYTWO,
    }
  },
})
